import { useEffect, useRef, useState } from 'react'
import MuiContainer, {
  ContainerProps as IMuiContainerProps,
} from '@mui/material/Container'

interface IContainerProps extends IMuiContainerProps {
  /**
   * Extends container height up to remaining height of viewport
   */
  fixedHeight?: boolean
}

export const ContainerUI = ({ fixedHeight, ...rest }: IContainerProps) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const [y, setY] = useState<number | undefined>()

  useEffect(() => {
    if (fixedHeight) {
      const handleResize = () => {
        const paddingBottom = cumulativeBottomPadding(
          boxRef.current?.parentElement
        )
        const offsetTop = boxRef.current?.offsetTop ?? 0
        setY(offsetTop + paddingBottom + 4)
      }

      const cumulativeBottomPadding = (element?: Element | null): number => {
        if (!element) {
          return 0
        }
        const computedStyle = window.getComputedStyle(element)
        const paddingBottom = parseInt(computedStyle.paddingBottom)
        return paddingBottom + cumulativeBottomPadding(element.parentElement)
      }

      handleResize()

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [fixedHeight, rest.children])

  return (
    <MuiContainer
      ref={boxRef}
      {...rest}
      sx={{
        paddingY: rest.disableGutters ? 0 : 2,
        height: fixedHeight ? `calc(100vh - ${y}px)` : 'auto',
        ...rest.sx,
      }}
    >
      {rest.children}
    </MuiContainer>
  )
}
