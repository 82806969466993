import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { ButtonUI } from 'common/components/inputs/button-ui'
import { ContainerUI } from 'common/components/layout/container-ui'
import { VStack } from 'common/components/layout/v-stack'
import { FormEvent, useState } from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { emitError } from 'common/utils/event-emitter'

type FormStates = {
  email: string
  password: string
  showPasswordStatus: 'show' | 'hide'
}

type FormStateKey = keyof FormStates

type FormStateValue = FormStates[FormStateKey]

const AuthLogin = () => {
  const router = useRouter()
  const [formStates, setFormStates] = useState<FormStates>({
    email: '',
    password: '',
    showPasswordStatus: 'hide',
  })

  const handleOnChange = (key: FormStateKey, value: FormStateValue) =>
    setFormStates((prevState) => ({
      ...prevState,
      [key]: value,
    }))

  const handleOnLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const response = await signIn('credentials', {
        redirect: false,
        email: formStates.email,
        password: formStates.password,
        callbackUrl: '/',
      })

      if (response?.ok) {
        router.push('/')
      } else {
        throw new Error(response?.error ?? '')
      }
    } catch (e) {
      emitError(`Error trying to login. ${e ?? ''}`)
    }
  }
  return (
    <ContainerUI>
      <VStack maxWidth={360} margin={'auto'} justifyContent={'center'} minHeight={500}>
        <Card component={'form'} onSubmit={handleOnLogin}>
          <CardHeader title={'Login'} />
          <CardContent>
            <VStack gap={2}>
              <FormControl fullWidth>
                <OutlinedInput
                  onChange={({ target: { value } }) => handleOnChange('email', value)}
                  size='small'
                  type={'email'}
                  placeholder='Email'
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <OutlinedInput
                  size='small'
                  placeholder='Password'
                  onChange={({ target: { value } }) => handleOnChange('password', value)}
                  type={formStates.showPasswordStatus === 'show' ? 'text' : 'password'}
                  required
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        type='button'
                        aria-label={
                          formStates.showPasswordStatus === 'show' ? 'hide the password' : 'display the password'
                        }
                        onClick={() =>
                          handleOnChange(
                            'showPasswordStatus',
                            formStates.showPasswordStatus === 'hide' ? 'show' : 'hide'
                          )
                        }
                        edge='end'
                      >
                        {formStates.showPasswordStatus === 'hide' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </VStack>
          </CardContent>
          <CardActions>
            <ButtonUI fullWidth type='submit'>
              Login
            </ButtonUI>
          </CardActions>
        </Card>
      </VStack>
    </ContainerUI>
  )
}

export default AuthLogin
