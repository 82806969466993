import MuiButton, { ButtonProps as IMuiButtonProps } from '@mui/material/Button'

interface IExtendedButtonProps extends IMuiButtonProps {
  secondary?: boolean
}

export const ButtonUI = ({ secondary, ...rest }: IExtendedButtonProps) => {
  return (
    <MuiButton variant={secondary ? 'text' : 'contained'} size='small' {...rest}>
      {rest.children}
    </MuiButton>
  )
}
